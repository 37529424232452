import { getUserProfile } from "./authService"
import { VITE_AEM_URL, VITE_AEM_API_KEY } from "@utils/constants.util"
import axios, { AxiosResponse } from "axios"
import { getAuthToken } from "./authService"

interface ForexDataResponse {
	0: {
		rates: Record<string, number>
	}
}

export const getPrimaryCurrencyCode = (): string | undefined => {
	const userProfile = getUserProfile()

	return userProfile?.currencies?.find(
		(currency) => currency?.primaryCurrency === true
	)?.currencyCode
}

export const fetchForexData = async (base?: string | undefined) => {
	const primaryCurrencyCode = base || getPrimaryCurrencyCode()
	if (!primaryCurrencyCode) {
		throw new Error("Primary currency not found")
	}

	try {
		const response: AxiosResponse<ForexDataResponse> = await axios.get(
			`${VITE_AEM_URL}/api/forex?base=${primaryCurrencyCode}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getAuthToken()}`,
					Apikey: VITE_AEM_API_KEY,
					"edge-cache-tag": "forex"
				}
			}
		)
		return response.data[0]?.rates
	} catch (error) {
		throw new Error(`Failed to fetch forex data: ${error}`)
	}
}
