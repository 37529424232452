import "./main.css"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MantineProvider } from "@mantine/core"
import { BrowserRouter } from "react-router-dom"
import { theme, cssVariableResolver } from "./theme"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { UI_BASE_URL } from "@utils/constants.util"
import SplunkRUMConfig from "./splunkRUMConfig"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<MantineProvider
		theme={theme}
		cssVariablesResolver={cssVariableResolver}
		withGlobalClasses
	>
		<BrowserRouter basename={UI_BASE_URL}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</BrowserRouter>
	</MantineProvider>
)
SplunkRUMConfig()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
