import { Box } from "@mantine/core"
import { useIsMobile } from "@utils/device.utils"
import DOMPurify from "dompurify"
import parse from "html-react-parser"
import React from "react"

export interface AEMSvgData {
	path: string
	title?: string | null
	description?: string
	width?: number
	height?: number
}

interface AEMSvgProps {
	svgData: AEMSvgData | string
	className?: string
	style?: React.CSSProperties
	ariaRole?: React.AriaRole
	colorIcon?: string
	isUrl: boolean
}

// TODO: Santiago Ponce: Should be changed to only work with AEMSvgData and not strings
export const AEMSvg: React.FC<AEMSvgProps> = ({
	svgData,
	className,
	style,
	ariaRole = "img",
	colorIcon,
	isUrl = false
}) => {
	const isMobile = useIsMobile()

	const handleSVGProcessing = (svgString: string): string => {
		try {
			const parser = new DOMParser()
			const svgDoc = parser.parseFromString(svgString, "image/svg+xml")
			const svgElement = svgDoc.documentElement

			const clipPaths = svgElement.querySelectorAll("clipPath")
			for (let i = 0; i < clipPaths.length; i++) {
				const clipPath = clipPaths[i]
				clipPath.parentNode?.removeChild(clipPath)
			}

			const elementsWithClipPath = svgElement.querySelectorAll("[clip-path]")
			for (let i = 0; i < elementsWithClipPath.length; i++) {
				const element = elementsWithClipPath[i]
				element.removeAttribute("clip-path")
			}

			if (colorIcon) {
				const rectsWithFill = svgElement.querySelectorAll("rect[fill]")

				for (let i = 0; i < rectsWithFill.length; i++) {
					const rect = rectsWithFill[i]
					const fill = rect.getAttribute("fill")
					if (fill && fill !== "none") {
						rect.remove()
					}
				}

				const elementsToUpdate = svgElement.querySelectorAll("[fill], [stroke]")

				for (let i = 0; i < elementsToUpdate.length; i++) {
					const element = elementsToUpdate[i]
					const fill = element.getAttribute("fill")
					const stroke = element.getAttribute("stroke")

					if (fill && fill !== "none") {
						element.setAttribute("fill", colorIcon)
					}

					if (stroke && stroke !== "none") {
						element.setAttribute("stroke", colorIcon)
					}
				}
			}
			return new XMLSerializer().serializeToString(svgDoc)
		} catch (error) {
			return svgString
		}
	}

	if (isUrl && typeof svgData === "string") {
		return (
			<Box
				component="img"
				src={svgData}
				className={className}
				style={{
					display: isMobile ? "flex" : "inline-block",
					width: style?.width || "24px",
					height: style?.height || "24px",
					filter: colorIcon ? `color(${colorIcon})` : undefined,
					...style
				}}
				role={ariaRole}
			/>
		)
	}

	if (typeof svgData === "string" && !isUrl) {
		const sanitizedSVG = DOMPurify.sanitize(svgData)
		const processedSVG = handleSVGProcessing(sanitizedSVG)

		return (
			<div
				className={className}
				style={{
					display: isMobile ? "flex" : "inline-block",
					...style
				}}
				role={ariaRole}
				aria-hidden="true"
			>
				{parse(processedSVG)}
			</div>
		)
	}

	if (svgData instanceof Object) {
		const blob = new Blob([svgData?.path], { type: "image/svg+xml" })
		const url = URL.createObjectURL(blob)

		return (
			<Box
				style={{
					WebkitMaskImage: `url(${url})`,
					maskImage: `url(${url})`,
					WebkitMaskSize: "cover",
					maskSize: "cover",
					backgroundColor: "currentColor",
					width: svgData?.width || "24px",
					height: svgData?.height || "24px",
					...style
				}}
				className={className}
				role={ariaRole}
				aria-label={svgData?.description || svgData?.title || ""}
				onLoad={() => URL.revokeObjectURL(url)}
			/>
		)
	}
}
