import { Box, Collapse, UnstyledButton } from "@mantine/core"
import { IconCaretLeftFilled, IconCaretRightFilled } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { useRedirect } from "@hooks/useRedirect"
import classes from "./LinksGroup.module.css"
import { AEMSvgData } from "@components/AEMSvg"

export type LinkType =
	| "internal"
	| "internal-blank"
	| "external"
	| "route"
	| "public"

export interface LinkItem {
	label?: string
	icon?: AEMSvgData
	link?: string
	type?: LinkType
	appId?: string
	links?: LinkItem[]
	toolCode?: string
	params?: Map<string, string>
}

interface LinksGroupProps {
	label: string
	initiallyOpened?: boolean
	links?: LinkItem[]
	menuOpened: boolean
}

function LinksGroup({
	label,
	initiallyOpened,
	links,
	menuOpened
}: LinksGroupProps) {
	const { handleRedirect } = useRedirect()
	const [opened, setOpened] = useState(initiallyOpened || false)
	const hasLinks = Array.isArray(links)

	const ChevronIcon = opened ? IconCaretLeftFilled : IconCaretRightFilled
	const chevronId = opened ? "icon-chevron-left" : "icon-chevron-right"

	const handleToggle = () => {
		setOpened((o) => !o)
		const navigationClickEvent = new CustomEvent("navigation_click", {
			detail: {
				eVar58: window.location.href,
				eVar169: label || "",
				eVar168: "Sidebar menu click",
				eVar167: "Navigation",
				eVar170: "",
				event: "211"
			}
		})
		window.dispatchEvent(navigationClickEvent)
	}

	useEffect(() => {
		setOpened(menuOpened)
	}, [menuOpened])

	const redirectTo = (item: LinkItem, hasNestedLinks?: boolean) => {
		handleRedirect(item)
		const navigationClickEvent = new CustomEvent("navigation_click", {
			detail: {
				eVar58: window.location.href,
				eVar169: !hasNestedLinks ? item.label : label,
				eVar168: "Submenu click",
				eVar167: "Navigation",
				eVar170: item.link || "",
				event: "211"
			}
		})
		window.dispatchEvent(navigationClickEvent)
	}

	const renderLinks = (items: LinkItem[], depth = 0) => {
		return items.map((item, index) => {
			const [nestedOpened, setNestedOpened] = useState(false)
			const hasNestedLinks = Array.isArray(item.links) && item.links.length > 0
			const isLastItem = index === items.length - 1

			const handleNestedToggle = (e: React.MouseEvent) => {
				e.preventDefault()
				e.stopPropagation()
				setNestedOpened((o) => !o)
				const targetDepth = depth + 1
				const nestedLinks = item.links || []

				if (hasNestedLinks && nestedLinks.length > 0) {
					const navigationClickEvent = new CustomEvent("navigation_click", {
						detail: {
							eVar58: window.location.href,
							eVar169: item.label || "",
							eVar168: "Submenu click",
							eVar167: "Navigation",
							eVar170: "",
							event: "211"
						}
					})

					window.dispatchEvent(navigationClickEvent)
				}
			}

			const NestedChevronIcon = nestedOpened
				? IconCaretLeftFilled
				: IconCaretRightFilled

			return (
				<Box
					key={item.label}
					pl={depth * 6}
					className={classes.linksGroupContainer}
				>
					<UnstyledButton
						className={`${classes.linksGroupButton} ${classes.subLink}`}
						style={{
							borderBottom: !isLastItem
								? "1px solid var(--background-color-primary)"
								: "none"
						}}
						onClick={
							hasNestedLinks
								? handleNestedToggle
								: () => redirectTo(item, hasNestedLinks)
						}
						aria-expanded={hasNestedLinks && nestedOpened ? "true" : "false"}
					>
						<Box>{item.label}</Box>
						{hasNestedLinks && (
							<NestedChevronIcon
								className={classes.chevron}
								size="1rem"
								style={{
									transform: nestedOpened ? "rotate(-270deg)" : "rotate(0deg)"
								}}
							/>
						)}
					</UnstyledButton>
					{hasNestedLinks && item.links && (
						<Collapse in={nestedOpened}>
							{renderLinks(item.links, depth + 1)}
						</Collapse>
					)}
				</Box>
			)
		})
	}

	return (
		<>
			<UnstyledButton
				onClick={handleToggle}
				className={`${classes.linksGroupButton} ${classes.topLevelLink}`}
				aria-expanded={opened ? "true" : "false"}
			>
				<Box ml="md">{label}</Box>
				{hasLinks && (
					<ChevronIcon
						data-test-id={chevronId}
						id={chevronId}
						size="1rem"
						stroke={1.5}
						style={{
							transform: opened ? "rotate(-270deg)" : "rotate(90deg)"
						}}
					/>
				)}
			</UnstyledButton>
			{hasLinks && (
				<Collapse className={classes.linksGroupContainer} in={opened}>
					{renderLinks(links)}
				</Collapse>
			)}
		</>
	)
}

export default LinksGroup
