import {
	ActionIcon,
	Affix,
	Button,
	Container,
	Image,
	Input,
	MantineThemeOverride,
	Paper,
	PasswordInput,
	Pill,
	RingProgress,
	Select,
	Text,
	TextInput,
	Title,
	UnstyledButton,
	createTheme,
	rem
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"

import { IconCaretDownFilled } from "@tabler/icons-react"
import actionIconStyles from "./ActionIcon.module.css"
import affixStyles from "./Affix.module.css"
import buttonStyles from "./Button.module.css"
import checkBoxkStyle from "./Checkbox.module.css"
import dateInputStyles from "./DatePickerInput.module.css"
import imageStyles from "./Image.module.css"
import inputStyles from "./Input.module.css"
import passwordInputStyles from "./PasswordInput.module.css"
import pillStyles from "./Pill.module.css"
import selectStyles from "./Select.module.css"
import textStyles from "./Text.module.css"
import titleStyles from "./Title.module.css"
import unstyledButtonStyles from "./UnstyledButton.module.css"
import focusStyles from "./focus.module.css"

const CONTAINER_SIZES: Record<string, string> = {
	xxs: rem(300),
	xs: rem(400),
	sm: rem(500),
	md: rem(800),
	lg: rem(1256),
	xl: rem(1440),
	xxl: rem(1920)
}

export const theme: MantineThemeOverride = createTheme({
	colors: {
		royal: [
			"#021E3E",
			"#ACE3F7",
			"#2F6BB3",
			"#E87436",
			"#3E474A",
			"#051557",
			"#FFFFFF",
			"#B4CFFA",
			"#004ECB",
			"#052396",
			"#F0F5F9",
			"#173E8D",
			"#CBD7FF",
			"#A8C8CF",
			"#1E2746",
			"#11162A",
			"#181F39",
			"#263053",
			"#77FFFF",
			"#F5F5F5",
			"#D1D2D5",
			"#4F4F4F",
			"#E9F4FF",
			"#3F4B7F",
			"#E0E0E0",
			"#EBEEF7",
			"#4f4f4f",
			"#828282",
			"#11162b",
			"#e7e7e7"
		],
		brand: [
			"#E3F2FD",
			"#BBDEFB",
			"#90CAF9",
			"#64B5F6",
			"#42A5F5",
			"#2196F3",
			"#1E88E5",
			"#1976D2",
			"#1565C0",
			"#124E84",
			"#A8C8CF",
			"#77FFFF",
			"#ACE3F7",
			"#B4CFFA",
			"#e87d43",
			"#2F6BB3",
			"#0077be",
			"#ff7f00",
			"#333333",
			"#C9DAF6"
		],
		"dark-blue": [
			"#eceffe",
			"#d4daf7",
			"#a4b2f2",
			"#7388ee",
			"#4a65eb",
			"#344ee9",
			"#2942ea",
			"#1e35d1",
			"#172fbb",
			"#051557",
			"#181F39",
			"#021E3E",
			"#11162B",
			"#263053"
		],
		secondary: [
			"#FAFAFA",
			"#F5F5F5",
			"#EEEEEE",
			"#E0E0E0",
			"#BDBDBD",
			"#9E9E9E",
			"#757575",
			"#616161",
			"#424242",
			"#333333",
			"#E8E8E8",
			"#4F4F4F",
			"#F0F5F9",
			"#D1D2D5",
			"#B4B9CD"
		],
		"dark-mode": [
			"#B4B9CD",
			"#CBD7FF",
			"#A8C8CF",
			"#263053",
			"#051557",
			"#181F39"
		],
		translucentGray: [
			"rgba(248, 249, 250, 0.5)",
			"rgba(241, 243, 245, 0.5)",
			"rgba(233, 236, 239, 0.5)",
			"rgba(222, 226, 230, 0.5)",
			"rgba(206, 212, 218, 0.5)",
			"rgba(173, 181, 189, 0.5)",
			"rgba(134, 142, 150, 0.5)",
			"rgba(73, 80, 87, 0.5)",
			"rgba(52, 58, 64, 0.5)",
			"rgba(33, 37, 41, 0.5)",
			"rgba(1, 30, 62, 0.23)"
		]
	},
	primaryColor: "brand",
	primaryShade: 9,
	fontFamily: "Poppins, sans-serif",
	headings: {
		fontFamily: "Poppins, sans-serif",
		fontWeight: "600",

		// Individual heading properties
		sizes: {
			h3: {
				fontSize: rem(40)
			},
			h4: {
				fontSize: rem(36)
			},
			h5: {
				fontSize: rem(24)
			},
			h6: {
				fontSize: rem(16)
			}
		}
	},
	radius: {
		infoCard: "calc(4.625rem * var(--mantine-scale))",
		larger: "calc(1.5rem * var(--mantine-scale))"
	},
	components: {
		Button: Button.extend({
			classNames: buttonStyles,
			defaultProps: {
				radius: "xl"
			}
		}),
		Image: Image.extend({
			classNames: {
				root: imageStyles.root
			}
		}),
		Input: Input.extend({
			classNames: inputStyles
		}),
		TextInput: TextInput.extend({
			classNames: inputStyles
		}),
		Paper: Paper.extend({
			defaultProps: {
				radius: "larger"
			}
		}),
		RingProgress: RingProgress.extend({
			defaultProps: {
				roundCaps: true,
				rootColor: "white"
			}
		}),
		Select: Select.extend({
			classNames: selectStyles,
			defaultProps: {
				rightSection: <IconCaretDownFilled />,
				withCheckIcon: false,
				allowDeselect: false
			}
		}),
		Title: Title.extend({
			classNames: {
				root: titleStyles.root
			},
			styles: {
				root: {
					padding: 0
				}
			}
		}),
		Text: Text.extend({
			classNames: textStyles,
			styles: {
				root: {
					maxWidth: 919
				}
			}
		}),
		DatePickerInput: DatePickerInput.extend({
			classNames: dateInputStyles
		}),
		PasswordInput: PasswordInput.extend({
			classNames: passwordInputStyles
		}),
		Pill: Pill.extend({
			classNames: pillStyles
		}),
		UnstyledButton: UnstyledButton.extend({
			classNames: unstyledButtonStyles
		}),
		Affix: Affix.extend({
			classNames: affixStyles
		}),
		ActionIcon: ActionIcon.extend({
			classNames: actionIconStyles
		}),
		Container: Container.extend({
			vars: (_, { size, fluid }) => ({
				root: {
					"--container-size": fluid
						? "100%"
						: size !== undefined && size in CONTAINER_SIZES
							? CONTAINER_SIZES[size]
							: rem(size)
				}
			})
		}),
		Checkbox: ActionIcon.extend({
			classNames: checkBoxkStyle
		})
	},
	spacing: {
		xs: "0.625rem",
		sm: "0.75rem",
		md: "1rem",
		lg: "1.25rem",
		xl: "1.5rem"
	},
	focusClassName: focusStyles.focus,
	other: {
		gradients: {
			headline: "linear-gradient(90deg, #052396 0%, #39BDD0 100%)",
			"title-background":
				"linear-gradient(0deg, #051557 0%, #223BA2 100%), #051557",
			background:
				"linear-gradient(90deg, #173E8D 0%, #2352A3 50%, #032B71 100%)"
		}
	}
})
