import { ThemeIcon, Tooltip, UnstyledButton } from "@mantine/core"
import classes from "./IconLink.module.css"
import { useRedirect } from "../hooks/useRedirect"
import { LinkItem } from "./LinksGroup"
import { AEMSvg } from "@components/AEMSvg"

interface IconLinkProps {
	item: LinkItem
}

export function IconLink({ item }: IconLinkProps) {
	const { handleRedirect } = useRedirect()
	const iconPath = item.icon?.path ?? ""

	const handleClick = (item: LinkItem) => {
		handleRedirect(item)
		const navigationClickEvent = new CustomEvent("navigation_click", {
			detail: {
				eVar58: window.location.href,
				eVar169: item.label,
				eVar168: "Sidebar menu click",
				eVar167: "Navigation",
				eVar170: item.link || "",
				event: "211"
			}
		})
		window.dispatchEvent(navigationClickEvent)
	}

	return (
		<UnstyledButton
			className={classes.iconLink}
			onClick={() => handleClick(item)}
		>
			<Tooltip
				withArrow={true}
				arrowSize={29}
				arrowOffset={8}
				arrowRadius={3}
				arrowPosition={"side"}
				position={"right-end"}
				color={"var(--mantine-color-body)"}
				c={"var(--mantine-color-text)"}
				fw={600}
				mt={2}
				ml={10}
				label={item.label ?? ""}
				pt={10}
				pb={10}
				pl={20}
				pr={20}
				fz={16}
				styles={{
					tooltip: {
						boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
						borderTopRightRadius: "10px",
						borderBottomRightRadius: "10px"
					}
				}}
			>
				<ThemeIcon variant="transparent" className={classes.iconTheme}>
					{item && (
						<AEMSvg
							ariaRole="link"
							svgData={{
								path: iconPath,
								title: item.label ?? "",
								description: item.label ?? "",
								width: 24,
								height: 24
							}}
						/>
					)}
				</ThemeIcon>
			</Tooltip>
		</UnstyledButton>
	)
}
