import { PageWithNavigation } from "@components/templates/PageWithNavigation/PageWithNavigation"
import { Navigate, Route, Routes } from "react-router-dom"
import { AuthProvider, useAuthContext } from "./context/AuthProvider"
import { lazy, Suspense, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import {
	RouteMetadata,
	updateTealiumPageData,
	getMetadataFromPath,
	initializeTealium
} from "./services/TealiumService"
import { AppShellProvider } from "./context/AppShellProvider"
import { ConditionalPageLayout } from "@components/templates/ConditionalPageLayout/ConditionalPageLayout"
import IsBrazil from "@components/templates/IsBrazil/IsBrazil"

const ProtectedOrUnprotectedRoute = lazy(
	() => import("./context/ProtectedOrUnprotectedRoute")
)
const ProtectedRoute = lazy(() => import("./context/ProtectedRoute"))
const LoginPage = lazy(() => import("./pages/LoginPage"))
const Home = lazy(() => import("./pages/home/home"))
const Logout = lazy(() => import("./pages/logout"))
const SeeOurShips = lazy(() => import("./features/SeeOurShips/SeeOurShips"))
const PromotionHome = lazy(() => import("./pages/promotions/PromotionHome"))
const PromotionBrand = lazy(() => import("./pages/promotions/PromotionBrand"))
const PromotionsSearchResults = lazy(
	() => import("./pages/promotions/PromotionsSearchResults")
)
const InsightsHome = lazy(() => import("./pages/insights/InsightsHome"))
const TermsOfUse = lazy(() => import("./pages/terms-of-use/TermsOfUse"))
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy/PrivacyPolicy"))
const TravelAgentGuidelines = lazy(
	() => import("./pages/agent-guidelines/TravelAgentGuidelines")
)
const ErrorPage = lazy(() => import("./pages/error/ErrorPage"))

function getRouteMetadata(pathname: string): RouteMetadata {
	// Remove leading UI_BASE_URL if present
	const cleanPath = pathname.replace(/^\/ui/, "")
	return getMetadataFromPath(cleanPath)
}

function RouteAnalytics() {
	const location = useLocation()
	const { userProfile } = useAuthContext()
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const metadata = getRouteMetadata(location.pathname)

			if (!isScriptLoaded) {
				// First time initialization
				initializeTealium(metadata, userProfile ?? undefined)
				setIsScriptLoaded(true)
			} else {
				// Subsequent page views
				updateTealiumPageData(metadata, userProfile ?? undefined)
			}
		}, 100)

		return () => clearTimeout(timeoutId)
	}, [location.pathname, userProfile, isScriptLoaded])

	return null
}

const securedOnlyRoutes = [
	{ path: "/home", component: Home },
	{ path: "/logout", component: Logout },
	{ path: "/see-our-ships", component: SeeOurShips },
	{ path: "/promotion/home", component: PromotionHome },
	{ path: "/promotion/brand", component: PromotionBrand },
	{ path: "/promotion/results", component: PromotionsSearchResults },
	{ path: "/insights/*", component: InsightsHome }
]

const securedAndUnsecuredRoutes = [
	{ path: "/terms-of-use", component: TermsOfUse },
	{ path: "/privacy-policy", component: PrivacyPolicy },
	{ path: "/travel-agent-guidelines", component: TravelAgentGuidelines }
]

function App() {
	return (
		<AppShellProvider>
			<AuthProvider>
				<RouteAnalytics />
				<Routes>
					<Route
						path="/login"
						element={
							<Suspense fallback={<div />}>
								<LoginPage />
							</Suspense>
						}
					/>
					<Route
						element={
							<Suspense fallback={<div />}>
								<ProtectedOrUnprotectedRoute />
							</Suspense>
						}
					>
						{securedAndUnsecuredRoutes.map(({ path, component: Component }) => (
							<Route
								key={path}
								path={path}
								element={
									<Suspense fallback={<div />}>
										<ConditionalPageLayout>
											<Component />
										</ConditionalPageLayout>
									</Suspense>
								}
							/>
						))}
					</Route>
					<Route
						element={
							<Suspense fallback={<div />}>
								<ProtectedRoute />
							</Suspense>
						}
					>
						{securedOnlyRoutes.map(({ path, component: Component }) => (
							<Route
								key={path}
								path={path}
								element={
									<Suspense fallback={<div />}>
										<PageWithNavigation>
											<Component />
										</PageWithNavigation>
									</Suspense>
								}
							/>
						))}
					</Route>
					<Route path="*" element={<Navigate to="/home" replace />} />
					<Route
						path="/error"
						element={
							<Suspense fallback={<div />}>
								<ErrorPage />
							</Suspense>
						}
					/>
				</Routes>
			</AuthProvider>
		</AppShellProvider>
	)
}

export default App
