import { Anchor } from "@mantine/core"
import styles from "./Skip.module.css"

function Skip() {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
		if (event.code === "Space") {
			event.preventDefault()
			event.target.click()
		}
	}

	return (
		<Anchor
			className={styles.skip}
			tabIndex={0}
			href="#content"
			ta="center"
			underline="hover"
			fw={600}
			maw="100%"
			onKeyDown={handleKeyDown}
		>
			Skip to Content
		</Anchor>
	)
}
export default Skip
