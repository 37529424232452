import { BrandsValues } from "@utils/constants.util"
import { create } from "zustand"

type Brand = keyof typeof BrandsValues

const defaultValues: FiltersPromotionState = {
	brand: "R",
	ship: "all",
	destination: "all",
	departureDate: new Date(),
	adults: 2,
	children: 0,
	infants: 0,
	residency: null,
	currency: "USD",
	priceRange: [0, 10000],
	loyaltyNumber: "",
	promoCode: "",
	age55Plus: false,
	lawEnforcement: false,
	fireDepartmentOrEMT: false,
	militaryOrForces: false,
	refundable: false
}

export interface FiltersPromotionState {
	brand: Brand
	ship: string
	destination: string
	departureDate: Date
	adults: number
	children: number
	infants: number
	residency: string | null
	currency: string
	priceRange: [string | number, string | number]
	loyaltyNumber: string
	promoCode: string
	age55Plus: boolean
	lawEnforcement: boolean
	fireDepartmentOrEMT: boolean
	militaryOrForces: boolean
	refundable: boolean
}
export interface FiltersPromotionActions {
	setBrand: (brand: Brand) => void
	setShip: (ship: string) => void
	setDestination: (destination: string) => void
	setDepartureDate: (date: Date) => void
	setAdults: (adults: number) => void
	setChildren: (children: number) => void
	setInfants: (infants: number) => void
	setResidency: (residency: string) => void
	setCurrency: (currency: string) => void
	setFilter: (key: keyof FiltersPromotionState, value: unknown) => void
	setLoyaltyNumber: (loyaltyNumber: string) => void
	setPromoCode: (promoCode: string) => void
	setAge55Plus: (age55Plus: boolean) => void
	setLawEnforcement: (lawEnforcement: boolean) => void
	setFireDepartmentOrEMT: (fireDepartmentOrEMT: boolean) => void
	setMilitaryOrForces: (militaryOrForces: boolean) => void
	setRefundable: (refundable: boolean) => void
	setPriceRange: (range: [string | number, string | number]) => void
	resetFilters: (minPrice?: number, maxPrice?: number) => void
}

export const useFiltersPromotionStore = create<
	FiltersPromotionState & FiltersPromotionActions
>((set, get) => ({
	...defaultValues,
	setBrand: (brand: Brand) => set({ brand }),
	setShip: (ship: string) => set({ ship }),
	setDestination: (destination: string) => set({ destination }),
	setDepartureDate: (departureDate: Date) => set({ departureDate }),
	setAdults: (adults: number) => {
		if (adults < 1 || adults > 4) return
		const { children, infants } = get()
		const maxChildrenInfants = 4 - adults
		set({
			adults,
			children: Math.min(children, maxChildrenInfants),
			infants: Math.min(infants, maxChildrenInfants)
		})
	},
	setChildren: (children: number) => {
		if (children < 0 || children > 3) return
		const { adults } = get()
		const maxChildrenInfants = 4 - adults
		set({ children: Math.min(children, maxChildrenInfants) })
	},
	setInfants: (infants: number) => {
		if (infants < 0 || infants > 3) return
		const { adults } = get()
		const maxChildrenInfants = 4 - adults
		set({ infants: Math.min(infants, maxChildrenInfants) })
	},
	setResidency: (residency: string) => set({ residency }),
	setCurrency: (currency: string) => set({ currency }),
	setPriceRange: (range: [string | number, string | number]) =>
		set({ priceRange: range }),
	setFilter: (key: keyof FiltersPromotionState, value: unknown) =>
		set((state) => ({ ...state, [key]: value })),
	setLoyaltyNumber: (loyaltyNumber: string) => set({ loyaltyNumber }),
	setPromoCode: (promoCode: string) => set({ promoCode }),
	setAge55Plus: (age55Plus: boolean) => set({ age55Plus }),
	setLawEnforcement: (lawEnforcement: boolean) => set({ lawEnforcement }),
	setFireDepartmentOrEMT: (fireDepartmentOrEMT: boolean) =>
		set({ fireDepartmentOrEMT }),
	setMilitaryOrForces: (militaryOrForces: boolean) => set({ militaryOrForces }),
	setRefundable: (refundable: boolean) => set({ refundable }),
	resetFilters: (minPrice = 0, maxPrice = 10000) =>
		set({
			...defaultValues,
			priceRange: [minPrice, maxPrice]
		})
}))
