import React from "react"
import { Image, ImageProps } from "@mantine/core"

export interface AEMImageData {
	path: string
	title: string | null
	description: string
	width: number
	height: number | string
}

interface AEMImageProps extends Omit<ImageProps, "src" | "alt"> {
	imageData: AEMImageData
}

export const AEMImage: React.FC<AEMImageProps> = ({ imageData, ...props }) => {
	return (
		<Image
			src={imageData?.path}
			key={imageData?.path}
			alt={imageData?.description || imageData?.title || ""}
			style={{
				maxWidth: "100%",
				aspectRatio: `${imageData?.width} / ${imageData?.height}`
			}}
			{...props}
		/>
	)
}
