import React, { createContext, useContext, useState, useCallback } from "react"

interface AppShellContextType {
	isMenuOpen: boolean
	openMenu: () => void
	closeMenu: () => void
	toggleMenu: () => void
}

const AppShellContext = createContext<AppShellContextType | undefined>(
	undefined
)

export function AppShellProvider({ children }: { children: React.ReactNode }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const openMenu = useCallback(() => setIsMenuOpen(true), [])
	const closeMenu = useCallback(() => setIsMenuOpen(false), [])
	const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), [])

	return (
		<AppShellContext.Provider
			value={{ isMenuOpen, openMenu, closeMenu, toggleMenu }}
		>
			{children}
		</AppShellContext.Provider>
	)
}

export function useAppShellContext() {
	const context = useContext(AppShellContext)
	if (context === undefined) {
		throw new Error(
			"useAppShellContext must be used within an AppShellProvider"
		)
	}
	return context
}
