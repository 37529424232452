import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { LinkItem } from "../navigation/LinksGroup"
import { AuthService, getUserProfile } from "../services/authService"
import {
	APP_ID_PARAM,
	BASE_LEGACY_URL,
	CREATE_ACCOUNT_LEGACY_APP_ID,
	FORGOT_PASSWORD_LEGACY_APP_ID,
	SSO_PARAM,
	TOKEN_PARAM,
	APP_ID
} from "../utils/constants.util"
import { useAppShellContext } from "../context/AppShellProvider"
import { StorageKeys } from "@utils/user-config"
import secureLocalStorage from "react-secure-storage"

export const useRedirect = () => {
	const navigate = useNavigate()
	const { closeMenu } = useAppShellContext()

	const handleRedirect = useCallback(
		async ({ link, type, appId, params }: LinkItem) => {
			if (!link || !type) {
				return console.error("Unknown link or type")
			}

			closeMenu?.()

			switch (type) {
				case "internal-blank": {
					const internalUrl = await buildInternalRedirectUrl(link, params)
					if (internalUrl) window.open(internalUrl, "_blank")
					break
				}
				case "internal": {
					const internalUrl = await buildInternalRedirectUrl(link, params)
					secureLocalStorage.setItem(
						StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY,
						"true"
					)
					if (internalUrl) window.location.href = internalUrl
					break
				}
				case "external": {
					const userProfile = getUserProfile()
					if (appId === APP_ID.EQUOTE && !userProfile?.eQuoteAcceptanceTC) {
						handleRedirect({
							link: "eQuote",
							type: "internal"
						})
						break
					}
					const externalUrl = await buildExternalRedirectUrl(
						link,
						appId,
						params
					)
					if (externalUrl) window.open(externalUrl, "_blank")
					break
				}
				case "route":
					navigate(link)
					break
				case "public":
					if (
						appId === CREATE_ACCOUNT_LEGACY_APP_ID ||
						appId === FORGOT_PASSWORD_LEGACY_APP_ID
					) {
						window.open(link, "_self")
						break
					}
					window.open(link, "_blank")
					break
				default:
					console.error("Unknown link type")
			}
		},
		[navigate, closeMenu]
	)

	const buildInternalRedirectUrl = async (
		target: string,
		params?: Map<string, string>
	) => {
		try {
			const token = AuthService.getAuthToken()
			if (!token) {
				return null
			}

			const remoteToken = await AuthService.remoteCPAccess(target)

			if (!remoteToken.remote_token) {
				return null
			}
			const urlObj = new URL(BASE_LEGACY_URL)
			urlObj.searchParams.set("token", remoteToken.remote_token)
			if (params) {
				params.forEach((value, key) => urlObj.searchParams.set(key, value))
			}
			return urlObj.toString()
		} catch (error) {
			return null
		}
	}

	const buildExternalRedirectUrl = async (
		link: string,
		appId?: string,
		params?: Map<string, string>
	) => {
		const userProfile = AuthService.getUserProfile()
		const { agencyId, agentId } = userProfile
		const urlObj = new URL(link)
		if (
			link.includes("agency_id") &&
			link.includes("tagent_id") &&
			agencyId &&
			agentId
		) {
			urlObj.searchParams.set("agency_id", agencyId.toString())
			urlObj.searchParams.set("tagent_id", agentId.toString())
			return urlObj.href
		}

		if (params) {
			params.forEach((value, key) => urlObj.searchParams.set(key, value))
		}

		try {
			if (!appId) {
				return null
			}

			const externalToken = await AuthService.webserviceAccess(appId)
			if (!externalToken) {
				return null
			}

			urlObj.searchParams.append(APP_ID_PARAM, appId)
			if (appId === APP_ID.EQUOTE || appId === APP_ID.VERB) {
				urlObj.searchParams.append(SSO_PARAM, externalToken.remoteAccessToken)
			} else {
				urlObj.searchParams.append(TOKEN_PARAM, externalToken.remoteAccessToken)
			}

			return urlObj.href
		} catch (error) {
			return null
		}
	}

	return { handleRedirect, buildInternalRedirectUrl }
}
