import { Stack, Group, Text, Flex, Anchor, Box, Divider } from "@mantine/core"
import styles from "./AppFooter.module.css"
import { useAEMComponentData, useAEMNavData } from "@AEM/AEMContentLoader"
import {
	AEMComponentMapper,
	AEMLeafNode,
	AEMPageType
} from "@AEM/AEMPage.model"
import { useRedirect } from "@hooks/useRedirect"
import { AEMImage } from "@components/AEMImage"
import { useAuthContext } from "../context/AuthProvider"
import { LinkType } from "./LinksGroup"

function AppFooter({ useDivider }: { useDivider?: boolean }) {
	const mappedDataQaAttribute = (link: string): string => {
		const base = "footer.link."
		switch (link) {
			case "/privacy-policy":
				return `${base}privacy`
			case "/terms-of-use":
				return `${base}terms`
			case "/travel-agent-guidelines":
				return `${base}guidelines`
			default:
				break
		}
		if (link.includes("about")) {
			return `${base}about`
		}
		return `${base}${link}`
	}

	const { componentData: footerComponentData } = useAEMComponentData(
		AEMPageType.FOOTER,
		AEMComponentMapper.APP_FOOTER
	)
	const { data: navigationFooterComponentData } = useAEMNavData(
		AEMPageType.NAVIGATION_FOOTER
	)

	const { isAuthenticated } = useAuthContext()
	const { handleRedirect } = useRedirect()

	const footerClick = (linkdata: AEMLeafNode, action: "logo" | "link") => {
		const eventDetail = {
			eVar58: window.location.href,
			eVar169: linkdata?.label || "",
			eVar168: action === "logo" ? "Logo click" : "Link click",
			eVar167: "Footer Interaction",
			eVar170: linkdata?.link,
			event: "211"
		}

		const navigationClickEvent = new CustomEvent("header_click", {
			detail: eventDetail
		})
		window.dispatchEvent(navigationClickEvent)
	}

	return (
		<Stack
			gap={"40px"}
			align={"center"}
			className={styles["footer-root"]}
			px={"lg"}
			py={"40"}
		>
			{useDivider && (
				<Divider className={styles["footer-divider"]} size={16} px={"xl"} />
			)}
			<Flex
				direction={{ base: "column", sm: "row" }}
				align={"center"}
				gap={{ base: "sm", sm: "xl" }}
			>
				{navigationFooterComponentData?.[0]?.children.items
					.filter((item) => "link" in item)
					.map((link: AEMLeafNode, index: number) => {
						// [PROVISIONAL SOLUTION] - This should be differentiated from other links with the data coming from AEM
						if (
							!isAuthenticated &&
							link.type !== "public" &&
							link.link === "/travel-agent-guidelines"
						)
							return null
						return (
							<Anchor
								className={styles["footer-link"]}
								key={index}
								fz={12}
								fw={500}
								c={"white"}
								component={"button"}
								onClick={() => {
									footerClick(link, "link")
									handleRedirect({
										link: link.link,
										type: link.type as LinkType
									})
								}}
								data-qa={mappedDataQaAttribute(link.link)}
							>
								{link.label}
							</Anchor>
						)
					})}
			</Flex>
			<Group
				justify="center"
				align="flex-end"
				className={styles["footer-rcg-logo"]}
			>
				<Box className={styles.separator} />
				<AEMImage
					imageData={footerComponentData?.footerLogo}
					w={220}
					h={80}
					mb={-10}
				/>
				<Box className={styles.separator} />
			</Group>
			<Flex
				direction={{ base: "column", sm: "row" }}
				justify={"center"}
				align={"center"}
				gap={{ base: "lg", sm: "xl" }}
			>
				<AEMImage
					w={102}
					h={27}
					imageData={{ ...footerComponentData?.subsidiaryLogos?.[0] }}
				/>
				<AEMImage
					w={137}
					h={28}
					imageData={{ ...footerComponentData?.subsidiaryLogos?.[1] }}
				/>
				<AEMImage
					w={98}
					h={10}
					imageData={{ ...footerComponentData?.subsidiaryLogos?.[2] }}
				/>
			</Flex>
			<Text
				fz={11}
				fw={500}
				lh={2.2}
				c={"white"}
				ta={{ base: "center", sm: "left" }}
			>
				{footerComponentData?.copyrightText}
			</Text>
		</Stack>
	)
}

export default AppFooter
