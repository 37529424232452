import { Paper, Stack, Text } from "@mantine/core"
import { useEffect, useState } from "react"
import styles from "./CurrencyConverter.module.css"
import { CurrencyInput } from "@components/ui/CurrencyInput/CurrencyInput"
import { fetchForexData, getPrimaryCurrencyCode } from "@services/forexCurrency"

export interface AmountChange {
	currency: string
	amount: number
	currencyOnly?: boolean
}

export function CurrencyConverter() {
	const [fromAmount, setFromAmount] = useState<{
		amount: number
		currency: string
	} | null>(null)
	const [toAmount, setToAmount] = useState<{
		amount: number
		currency: string
	} | null>(null)

	const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>(
		{}
	)
	const [primaryCurrencyCode, setPrimaryCurrencyCode] = useState<string | null>(
		null
	)
	const [secondaryCurrencyCode, setSecondaryCurrencyCode] = useState<
		string | null
	>(null)

	// Initialize primary currency on first render
	useEffect(() => {
		const getUserProfileAndCurrencies = async () => {
			const primaryCurrencyCode = getPrimaryCurrencyCode() ?? "USD"
			setPrimaryCurrencyCode(primaryCurrencyCode)
			setFromAmount({ amount: 1, currency: primaryCurrencyCode })
		}

		void getUserProfileAndCurrencies()
	}, [])

	// Update forex rates when primary code changes
	useEffect(() => {
		const fetchData = async () => {
			if (primaryCurrencyCode) {
				const rates = await fetchForexData(primaryCurrencyCode)
				setExchangeRates(rates)
			}
		}

		void fetchData()
	}, [primaryCurrencyCode])

	const handleFromChange = ({ amount, currency }: AmountChange) => {
		setFromAmount({ amount, currency })
		if (
			!currency ||
			!toAmount ||
			!secondaryCurrencyCode ||
			currency === toAmount.currency ||
			!exchangeRates?.[currency] ||
			!exchangeRates[secondaryCurrencyCode]
		)
			return

		const conversionRate =
			(amount * exchangeRates[secondaryCurrencyCode]) / exchangeRates[currency]
		setToAmount({ amount: conversionRate, currency: secondaryCurrencyCode })
	}

	const handleToChange = (amountChange: AmountChange) => {
		const { amount, currency, currencyOnly } = amountChange

		// If the "to" field changes just its currency, reset primary to 1 and update secondary amount
		if (currencyOnly && primaryCurrencyCode) {
			const conversionRate =
				exchangeRates[currency] / exchangeRates[primaryCurrencyCode]
			setFromAmount({ amount: 1, currency: primaryCurrencyCode })
			setToAmount({ amount: conversionRate, currency })

			return
		}

		setToAmount({ amount, currency })

		if (
			!currency ||
			!fromAmount ||
			!primaryCurrencyCode ||
			currency === fromAmount.currency ||
			!exchangeRates?.[currency] ||
			!exchangeRates[primaryCurrencyCode]
		)
			return

		const reverseConversionRate =
			amount / exchangeRates[currency] / exchangeRates[primaryCurrencyCode]
		setFromAmount({
			amount: reverseConversionRate,
			currency: primaryCurrencyCode
		})
	}

	// Format the display values to two decimal places
	const fromDisplay = fromAmount ? fromAmount.amount.toFixed(2) : "0.00"
	const toDisplay = toAmount ? toAmount.amount.toFixed(2) : "0.00"

	return (
		<Paper shadow="lg" p={30} radius="md" className={styles.currencyConverter}>
			<Stack gap="xs">
				<Text
					fz={18}
					fw={600}
					lh={1.2}
					ta="center"
					className={styles.currencyConverterText}
				>
					Currency Converter
				</Text>
				<Stack gap="xs" flex={1}>
					{fromAmount && exchangeRates ? (
						<>
							<CurrencyInput
								key="currency-from"
								forceAmount={fromAmount}
								onChange={handleFromChange}
								rates={exchangeRates}
								onCurrencySelect={setPrimaryCurrencyCode}
								disabledCurrencyCode={secondaryCurrencyCode}
							/>

							<CurrencyInput
								key="currency-to"
								forceAmount={toAmount}
								onChange={handleToChange}
								rates={exchangeRates}
								onCurrencySelect={setSecondaryCurrencyCode}
								disabledCurrencyCode={primaryCurrencyCode}
							/>
						</>
					) : (
						<Text>Loading...</Text>
					)}
				</Stack>

				{fromAmount &&
					toAmount &&
					fromAmount.amount > 0 &&
					toAmount.amount > 0 && (
						<Text mt="md" fz={16} fw={600} lh={1.5}>
							{fromDisplay} {fromAmount.currency} = {toDisplay}{" "}
							{toAmount.currency}
						</Text>
					)}
			</Stack>
		</Paper>
	)
}
