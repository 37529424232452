import React from "react"
import { Card, Grid, Text, Flex, Stack, Image } from "@mantine/core"
import styles from "./WeatherWidget.module.css"
import { forecastValues, locationValue } from "@services/weatherService"
import { WeatherObject } from "./WeatherWidget"

interface SevenDayForecastProps {
	forecast: forecastValues[]
	locationData: locationValue | null
	WindValues: string
	HumidityValue: string
	precipitationValue: number
	weatherData: WeatherObject
	forecastIcon: string
	unit: string
	toggleUnit: (newUnit: string) => void
}

const SevenDayForecast: React.FC<SevenDayForecastProps> = ({
	forecast,
	locationData,
	WindValues,
	HumidityValue,
	precipitationValue,
	weatherData,
	forecastIcon,
	unit,
	toggleUnit
}) => {
	const formatLocalTime = (time: string) => {
		const date = new Date(time)
		const options: Intl.DateTimeFormatOptions = {
			weekday: "long",
			hour: "numeric",
			minute: "numeric",
			hour12: true
		}

		return new Intl.DateTimeFormat("en-US", options).format(date)
	}

	const getTemperatureForDate = (date: string) => {
		const currentDate = new Date().toISOString().split("T")[0]
		const forecastItem = forecast.find((item) => item.date === currentDate)
		if (forecastItem) {
			return { minTemp: forecastItem.minTemp, maxTemp: forecastItem.maxTemp }
		}
		return { minTemp: 0, maxTemp: 0 }
	}

	const { minTemp, maxTemp } = getTemperatureForDate(
		locationData?.localTime || ""
	)
	const minTemperature = unit === "m" ? `${minTemp} °C` : `${minTemp} °F`

	const maxTemperature = unit === "m" ? `${maxTemp} °C` : `${maxTemp} °F`

	return (
		<Card padding="lg" radius="md" className={styles.cardTopBorder}>
			<Flex
				justify="space-between"
				align="center"
				mt="md"
				className={styles.mar20}
			>
				<Stack gap={0.4}>
					<Stack gap={0.4}>
						<Flex align="center" mt="md" gap="xs">
							<Text
								size="md"
								fw={500}
								className={`${styles.weatherWidgetText}`}
							>
								{locationData?.name}, {locationData?.region},{" "}
								{locationData?.country}
							</Text>
							<Text size="xs" fw={500} className={styles.weatherWidgetTime}>
								{locationData?.localTime
									? formatLocalTime(locationData.localTime)
									: ""}
							</Text>
						</Flex>
					</Stack>
					<Stack gap={0.4}>
						<Flex align="center">
							<Text
								size="xs"
								className={`${unit === "m" ? `${styles.weatherWidgetText} ${styles.weatherTextDecoration}` : styles.weatherWidgetTime} ${unit === "m" ? styles.selectedUnit : ""}`}
								onClick={() => toggleUnit("m")}
							>
								°C
							</Text>
							<Text
								size="xs"
								className={`${styles.weatherWidgetTime} ${styles.m5}`}
							>
								|
							</Text>
							<Text
								size="xs"
								className={`${unit === "f" ? `${styles.weatherWidgetText} ${styles.weatherTextDecoration}` : styles.weatherWidgetTime} ${unit === "f" ? styles.selectedUnit : ""}`}
								onClick={() => toggleUnit("f")}
							>
								°F
							</Text>
						</Flex>
					</Stack>
					<Stack>
						<Flex justify="space-between" align="center" mt="md" gap="xs">
							<Image
								src={weatherData?.icon}
								w="100%"
								h={60}
								className="${styles.marginRight}"
							/>
							<Flex columnGap="xl">
								<Stack gap={0.4}>
									<Text size="xs">High</Text>
									<Text fw={600} size="xl" className={styles.font48}>
										{maxTemperature}
									</Text>
								</Stack>
								<Stack gap={0.4}>
									<Text size="xs">Low</Text>
									<Text
										fw={600}
										size="xl"
										className={`${styles.weatherWidgetTime} ${styles.font48}`}
									>
										{minTemperature}
									</Text>
								</Stack>
							</Flex>
						</Flex>
					</Stack>
					<Stack>
						<Text size="sm" mt="sm">
							{weatherData.description}
						</Text>
					</Stack>
				</Stack>
				<Stack gap="xs">
					<Text size="md" className={styles.weatherWidgetTime}>
						Wind: {WindValues}
					</Text>
					<Text size="md" className={styles.weatherWidgetTime}>
						Humidity: {HumidityValue}
					</Text>
					<Text size="md" className={styles.weatherWidgetTime}>
						Precipitation: {precipitationValue}%
					</Text>
				</Stack>
			</Flex>

			{/* 7-Day Forecast */}
			<Grid
				mt="lg"
				pl="lg"
				pt="lg"
				pb="lg"
				justify="flex-start"
				className={styles.forecastGrid}
			>
				{forecast.map((forecastItem, index) => {
					const forecastDate = new Date(`${forecastItem.date}T00:00:00`)
					const weekday = forecastDate.toLocaleString("en-US", {
						weekday: "short"
					})
					const day = forecastDate.getDate()

					return (
						<Grid.Col span={1.5} key={index} className={`${styles.mar10}}`}>
							<Card shadow="sm" padding="xs" radius="md" withBorder>
								<Stack justify="center" align="center">
									<Flex gap="xs">
										<Text ta="center" fw={500} size="xs">
											{weekday}
										</Text>
										<Text ta="center" fw={500} size="xs">
											{day}
										</Text>
									</Flex>
									<Image src={forecastIcon} w={20} h={20} />
									<Flex gap="xs">
										<Text
											ta="center"
											size="sm"
											className={styles.weatherWidgetText}
										>
											{forecastItem.maxTemp}°
										</Text>
										<Text
											ta="center"
											size="sm"
											className={styles.weatherWidgetTime}
										>
											{" "}
											{forecastItem.minTemp}°
										</Text>
									</Flex>
								</Stack>
							</Card>
						</Grid.Col>
					)
				})}
			</Grid>
		</Card>
	)
}

export default SevenDayForecast
