import { Select, Group, NumberInput } from "@mantine/core"
import { useEffect, useState } from "react"
import countriesData from "@utils/countriesCurrencies.json" // Make sure this contains all 169 countries
import { minValueNumberInput, maxValueNumberInput } from "@utils/constants.util"
import styles from "../CurrencyConverter/CurrencyConverter.module.css"
import { IconChevronDown } from "@tabler/icons-react"
import { AmountChange } from "@components/ui/CurrencyConverter/CurrencyConverter"

interface CurrencyInputProps {
	forceAmount: { amount: number; currency: string } | null
	onChange: (amountChange: AmountChange) => void
	rates: { [key: string]: number }
	onCurrencySelect: (currency: string) => void
	disabledCurrencyCode: string | null
}

interface CurrencyOption {
	value: string
	label: string
	flag: string
	disabled?: boolean
}

export function CurrencyInput({
	forceAmount,
	onChange,
	rates,
	onCurrencySelect,
	disabledCurrencyCode
}: CurrencyInputProps) {
	const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([])
	const [searchValue, setSearchValue] = useState<string>("")
	const [currency, setCurrency] = useState<string | null>(
		forceAmount?.currency ?? null
	)

	useEffect(() => {
		forceAmount && setCurrency(forceAmount.currency)
	}, [forceAmount?.currency])

	useEffect(() => {
		const options = Object.keys(rates).map((currencyCode) => {
			const country = countriesData.find(
				(country) => country.code === currencyCode
			)
			if (!country) return null
			const countryName = country ? country.name : currencyCode
			const flag = country?.flag || ""
			const label = countryName || ""

			return {
				value: currencyCode,
				label: `${currencyCode} - ${label}`,
				flag: flag,
				disabled: currencyCode === disabledCurrencyCode
			}
		})

		setCurrencyOptions(options.filter((option) => option !== null))
	}, [rates, disabledCurrencyCode])

	// Handle amount change
	const handleAmountChange = (value: string | number) => {
		const parsedValue =
			typeof value === "string" ? Number.parseFloat(value) : value
		const fixed = forceAmount ? Number(forceAmount.amount.toFixed(2)) : null
		if (!currency || parsedValue === fixed) return

		onChange({ amount: parsedValue, currency })
	}

	// Handle currency selection change
	const handleCurrencyChange = (value: string | null) => {
		if (value !== null) {
			setCurrency(value)
			onCurrencySelect(value)
			onChange({ amount: 1, currency: value, currencyOnly: true })
		}
	}

	// If user selects already-selected option, make sure the input keeps the proper values
	const handleSelect = (value: string | null) => {
		if (value === currency) {
			setCurrency(value)
			setSearchValue(
				currencyOptions.find((option) => option.value === value)?.label ?? ""
			)
		}
	}

	const CurrencyItem = ({ label, flag }: CurrencyOption) => (
		<div className={styles.center}>
			{flag && (
				<img
					src={flag}
					alt={`${label} flag`}
					className={styles.currencyListItem}
				/>
			)}
			<span className={styles.currencyLabel}>{label}</span>
		</div>
	)

	const SelectedCurrencyDisplay = () => {
		const selectedOption = currencyOptions.find(
			(option) => option.value === currency
		)

		if (
			selectedOption?.label.toLocaleLowerCase() ===
			searchValue.toLocaleLowerCase()
		) {
			return (
				<div className={styles.selectCurrency}>
					{selectedOption.flag && (
						<img
							src={selectedOption.flag}
							alt={`${selectedOption.label} flag`}
							className={styles.currencyItemImg}
						/>
					)}
				</div>
			)
		}
		return null
	}

	return (
		<Group flex={1} wrap="nowrap">
			<NumberInput
				allowDecimal
				hideControls={true}
				min={minValueNumberInput}
				max={maxValueNumberInput}
				placeholder="-"
				onChange={handleAmountChange}
				value={forceAmount?.amount.toFixed(2)}
				w={43}
				variant="small-input"
				disabled={!forceAmount}
			/>
			<Select
				flex={1}
				data={currencyOptions}
				rightSectionWidth={20}
				rightSection={<IconChevronDown />}
				leftSection={<SelectedCurrencyDisplay />}
				leftSectionWidth={20}
				rightSectionPointerEvents="none"
				value={currency}
				searchValue={searchValue}
				onSearchChange={setSearchValue}
				onFocus={() => setSearchValue("")}
				onClick={() => setSearchValue("")}
				onChange={handleCurrencyChange}
				onOptionSubmit={handleSelect}
				comboboxProps={{ withinPortal: false }}
				variant="small-select"
				renderOption={(item) => {
					const { value, label, flag } = item.option as CurrencyOption
					return <CurrencyItem value={value} label={label} flag={flag} />
				}}
				placeholder={"Select Currency"}
				classNames={{ input: styles.selectInput }}
				searchable
				clearable
			/>
		</Group>
	)
}
