import SplunkRum from "@splunk/otel-web"
import SplunkRumRecorder from "@splunk/otel-web-session-recorder"

const SplunkRUMConfig = () => {
	const realm = import.meta.env.VITE_SPLUNK_REALM
	const rumAccessToken = import.meta.env.VITE_SPLUNK_RUM_ACCESS_TOKEN
	const applicationName = import.meta.env.VITE_SPLUNK_APPLICATION_NAME
	const deploymentEnvironment = import.meta.env.VITE_SPLUNK_ENVIRONMENT

	if (!realm || !rumAccessToken || !applicationName || !deploymentEnvironment) {
		console.error(
			"Splunk RUM configuration is missing. Check your environment variables."
		)
		return
	}

	SplunkRum.init({
		realm: realm,
		rumAccessToken: rumAccessToken,
		applicationName: applicationName,
		deploymentEnvironment: deploymentEnvironment
	})

	// Initialize Session Recorder after Splunk RUM is initialized
	SplunkRumRecorder.init({
		realm: realm, // Use the same realm as RUM
		rumAccessToken: rumAccessToken // Use the same access token as RUM
	})
}

export default SplunkRUMConfig
