import { useEffect } from "react"
import { useAuthContext } from "../../context/AuthProvider"
import { AuthService } from "../../services/authService"
import styles from "./WebChatWidget.module.css"
// Declare the global WebChatWidgetProps interface
declare global {
	interface Window {
		WebChatWidgetProps: {
			z: string
			appId: string
			domain: string
			environment: string
			providerCode: string
			agentEmail: string
			agentFirstName: string
			agentLastName: string
			betaVersion: boolean
			sessionCreatedTime: number
			baseUrl: string
			token: string
		}
		$: JQueryStatic
		jQuery: JQueryStatic
		Pypestream: {
			config: (config: unknown) => void
			boot: (options: { APP_ID: string }, element: HTMLElement) => void
			toggle: () => void
			shutdown: () => void
			onChatRestart: (callback: () => void) => void
			onShow: (callback: () => void) => void
			onHide: (callback: () => void) => void
		}
	}
}

export const WebChatWidget = () => {
	const { isAuthenticated } = useAuthContext()
	const { userProfile } = useAuthContext()

	useEffect(() => {
		if (isAuthenticated && userProfile?.latteAvailable) {
			// Create launch button elements
			const launchButton = document.createElement("div")
			launchButton.id = "launch"
			launchButton.className = styles.launchButton

			const icon = document.createElement("div")
			icon.id = "icon"
			icon.className = styles.chatIcon

			const alert = document.createElement("div")
			alert.id = "launchIcon-alert"
			alert.className = `${styles["chatIcon-alert"]} ${styles.hide}`
			alert.innerHTML = "<span>1</span>"

			const text = document.createElement("div")
			text.id = "icon-text"
			text.className = styles["chatIcon-text"]
			text.innerHTML = "<span>Chat with Latte</span>"

			launchButton.appendChild(text)
			launchButton.appendChild(icon)
			launchButton.appendChild(alert)
			document.body.appendChild(launchButton)

			// Create a hidden logout button for the widget
			const logoutBtn = document.createElement("button")
			logoutBtn.id = "logoutBtn"
			logoutBtn.style.display = "none"
			document.body.appendChild(logoutBtn)

			// Set up WebChatWidgetProps
			const initializeProps = async () => {
				const { remoteAccessToken } = await AuthService.webserviceAccess(
					import.meta.env.VITE_WEBCHAT_PROVIDER_CODE
				)
				window.WebChatWidgetProps = {
					z: remoteAccessToken,
					appId: userProfile.latteKey || "",
					domain: import.meta.env.VITE_WEBCHAT_DOMAIN,
					environment: import.meta.env.VITE_WEBCHAT_ENVIRONMENT,
					providerCode: import.meta.env.VITE_WEBCHAT_PROVIDER_CODE,
					agentEmail: userProfile.agentEmailAddress || "",
					agentFirstName: userProfile.agentFirstName || "",
					agentLastName: userProfile.agentLastName || "",
					betaVersion: import.meta.env.VITE_WEBCHAT_BETA_VERSION === "true",
					sessionCreatedTime: Number(AuthService.getLoginDate()) || Date.now(),
					baseUrl: import.meta.env.VITE_BASE_URL,
					token: AuthService.getAuthToken() || ""
				}
			}

			const loadScript = (src: string): Promise<void> => {
				return new Promise((resolve, reject) => {
					const script = document.createElement("script")
					script.src = src
					script.async = true
					script.onload = () => resolve()
					script.onerror = () =>
						reject(new Error(`Failed to load script: ${src}`))
					document.body.appendChild(script)
				})
			}

			const initializeWebChat = async () => {
				try {
					await initializeProps()

					// Load jQuery first
					await loadScript("https://code.jquery.com/jquery-3.6.0.min.js")

					// Then load Pypestream
					await loadScript(import.meta.env.VITE_WEBCHAT_PUBLIC_URL)

					// Finally load our widget script
					await loadScript(
						`${import.meta.env.VITE_UI_BASE_URL}${import.meta.env.VITE_UI_BASE_URL !== "/" ? "/" : ""}webChatWidget.js`
					)
				} catch (error) {
					console.error("Failed to initialize WebChat:", error)
				}
			}

			initializeWebChat()

			return () => {
				// Cleanup
				const launchButton = document.getElementById("launch")
				const logoutBtn = document.getElementById("logoutBtn")
				if (launchButton) document.body.removeChild(launchButton)
				if (logoutBtn) document.body.removeChild(logoutBtn)

				// Shutdown Pypestream if it exists
				if (window.Pypestream) {
					window.Pypestream.shutdown()
				}

				// Remove scripts
				const scripts = document.querySelectorAll("script")
				for (const script of scripts) {
					if (
						script.src.includes("webChatWidget.js") ||
						script.src.includes("jquery") ||
						script.src.includes(import.meta.env.VITE_WEBCHAT_PUBLIC_URL)
					) {
						document.body.removeChild(script)
					}
				}

				// Remove widget container
				const wcwContainer = document.getElementById("wcwContainer")
				if (wcwContainer) {
					document.body.removeChild(wcwContainer)
				}
			}
		}
	}, [isAuthenticated, userProfile?.latteAvailable])

	return null
}
