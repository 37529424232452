import { useMediaQuery } from "@mantine/hooks"
import { useMantineTheme } from "@mantine/core"

export const isBrowser = typeof window !== "undefined"
export const isServer = !isBrowser

export function useIsMobile() {
	const theme = useMantineTheme()
	return useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
}

export function useIsTablet() {
	const theme = useMantineTheme()
	return useMediaQuery(
		`(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.lg})`
	)
}

export function useIsDesktop() {
	const theme = useMantineTheme()
	return useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)
}

export function useDeviceType() {
	const isMobile = useIsMobile()
	const isTablet = useIsTablet()
	const isDesktop = useIsDesktop()

	if (isMobile) return "mobile"
	if (isTablet) return "tablet"
	if (isDesktop) return "desktop"
	return "unknown"
}
