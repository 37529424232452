export class LoginError {
	message: string
	errorCode: string
	remainingAttempts: number

	constructor(message: string, errorCode: string, remainingAttempts: number) {
		this.message = message
		this.errorCode = errorCode
		this.remainingAttempts = remainingAttempts
	}
}
