import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { AEMComponentMapper, AEMPageType } from "@AEM/AEMPage.model"
import {
	Anchor,
	Box,
	Divider,
	Modal,
	ModalProps,
	Stack,
	Text,
	useMantineTheme
} from "@mantine/core"

import XIcon from "@assets/svg/x.svg?react"

import styles from "./AssistanceModal.module.css"

export type AssistanceModalContactMethod = {
	label: string
	value: string
}

export function AssistanceModal(props: ModalProps) {
	const theme = useMantineTheme()
	const { componentData } = useAEMComponentData(
		AEMPageType.DICTIONARY,
		AEMComponentMapper.APP_DICTIONARY
	)
	return (
		componentData && (
			<Modal
				{...props}
				centered
				size="lg"
				classNames={{
					header: styles.modalHeader,
					content: styles.modalContent,
					body: styles.modalBody
				}}
				closeButtonProps={{
					"aria-label": "Close Assistance Modal",
					icon: (
						<XIcon
							color={theme.colors["dark-blue"][9]}
							viewBox={"6 6 12 12"}
							width={16}
							height={16}
						/>
					)
				}}
			>
				<Stack className={styles.container}>
					<Stack className={styles.innerContainer}>
						<Text
							fz={22}
							fw={600}
							lh={1.4}
							ta="center"
							c={theme.colors["dark-blue"][9]}
						>
							{componentData.furtherAssistanceModalTitle}
						</Text>
						<Text
							fz={13}
							fw={400}
							lh={1.5}
							c={theme.colors.secondary[11]}
							ta="center"
						>
							{componentData.furtherAssistanceModalBody}
						</Text>
					</Stack>
					<Divider />
					<Stack className={styles.innerContainer}>
						<Text
							fz={16}
							fw={600}
							lh={1.9}
							ta="center"
							c={theme.colors["dark-blue"][9]}
						>
							{componentData.furtherAssistanceModalSubheader}
						</Text>
						<Text c="#000000" fz={13} fw={400} lh={1.6} ta="center">
							{componentData.furtherAssistanceModalSubtext}
						</Text>
						<Box ta="center">
							<Box>
								<Text span c="#000000" fz={13} fw={400} lh={1.6}>
									{`${componentData.phone} `}
								</Text>
								<Anchor
									fw={500}
									c="#000000"
									fz={13}
									lh={1.6}
									href={`tel:${componentData.furtherAssistanceModalPhone}`}
								>
									{componentData.furtherAssistanceModalPhone}
								</Anchor>
							</Box>
							<Box>
								<Text span c="#000000" fz={13} fw={400} lh={1.6}>
									{`${componentData.email} `}
								</Text>
								<Anchor
									fz={13}
									fw={500}
									c={theme.colors["dark-blue"][9]}
									href={`mailto:${componentData.furtherAssistanceModalEmail}`}
								>
									{componentData.furtherAssistanceModalEmail}
								</Anchor>
							</Box>
						</Box>
						<Text c="#000000" fz={11} fw={400} lh={1.9}>
							{componentData.furtherAssistanceModalOpenHours}
						</Text>
					</Stack>
				</Stack>
			</Modal>
		)
	)
}
