export function deepMerge(obj1, obj2) {
	const merged = {}

	for (const key in obj1) {
		if (Object.prototype.hasOwnProperty.call(obj2, key)) {
			if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
				merged[key] = obj1[key].map((item, index) => {
					if (index < obj2[key].length) {
						return typeof item === "object"
							? deepMerge(item, obj2[key][index])
							: obj2[key][index]
					}
					return item
				})
			} else if (
				typeof obj1[key] === "object" &&
				typeof obj2[key] === "object"
			) {
				merged[key] = deepMerge(obj1[key], obj2[key])
			} else {
				merged[key] = obj2[key] || obj1[key]
			}
		} else {
			merged[key] = obj1[key]
		}
	}

	for (const key in obj2) {
		if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
			merged[key] = obj2[key]
		}
	}

	return merged
}
