export interface AEMCache {
	[locale: string]: {
		[page: string]: AEMPage
	}
}

export interface AEMPage {
	items: [
		{
			title?: string
			brand?: string
			components: AEMComponentType[]
		}
	]
}

export interface AEMLeafNode {
	children?: {
		items: (AEMSectionNode | AEMLeafNode)[]
	}
	label: string
	description: AEMDescriptionType
	toolCode: string
	link: string
	type: string
	section: boolean
	icon?: string
	appId?: string
}

export interface AEMSectionNode {
	label: string
	description: AEMDescriptionType
	section: boolean
	children: {
		items?: (AEMSectionNode | AEMLeafNode)[]
	}
}

export interface AEMDescriptionType {
	markdown: string
	plaintext: string
}

export interface AEMNavigation {
	[key: number]: {
		children: {
			items: (AEMSectionNode | AEMLeafNode)[]
		}
	}
}

export interface AEMAlert {
	data: {
		componentAlertBannerList?: { items: unknown[] }
		componentNotificationsList?: { items: unknown[] }
	}
}

export interface AEMComponentType {
	type: string
	// biome-ignore lint/suspicious/noExplicitAny: Used any since any type of key value pair could come from AEM
	[key: string]: any
}

export enum AEMPageType {
	HOME = "home",
	LOGIN = "login",
	FOOTER = "footer", //missing
	PROMOTIONS_HOME = "promotions-home",
	SEE_OUR_SHIPS = "see-our-ships",
	LANGUAGES = "languages", //missing
	DICTIONARY = "dictionary", //missing
	ALERTS = "alerts",
	NOTIFICATIONS = "notifications",
	NAVIGATION_FOOTER = "navigation-footer",
	NAVIGATION_GLOBAL = "navigation-global",
	NAVIGATION_SIDEBAR = "navigation-sidebar",
	NAVIGATION_HOME = "navigation-home",
	PAGES_HOME = "pages-home", //duplicated? cant use home in two keys
	PAGES_PRIVACY_POLICY = "privacy-policy",
	PAGES_TRAVEL_AGENT_GUIDELINES = "travel-agent-guidelines",
	PAGES_TERMS_OF_USE = "terms-of-use",
	PAGES_INSIGHTS_ALERT_CENTER = "insights-alert-center",
	PAGES_INSIGHTS_COUPON_DETAILS = "insights-coupon-application-details",
	PAGES_INSIGHTS_FUTURE_COMPENSATION = "insights-future-compensation",
	PAGES_INSIGHTS_INDIVIDUAL_RESERVATIONS = "insights-individual-reservations",
	PAGES_INSIGHTS_NPC_TRANSMISSION = "insights-npc-transmission-details",
	PAGES_INSIGHTS_OVERVIEW = "insights-overview",
	PAGES_INSIGHTS_AUTOMATED_EMAILS = "insights-set-up-automated-emails",
	PAGES_INSIGHTS_VIEW_BRANCH = "insights-view-another-branch",
	PAGES_PROMOTIONS_BRAND_LANDING_ROYAL = "promotions-brand-landing-royal",
	PAGES_PROMOTIONS_OCTOBER_OFFER = "promotions-october-offer",
	PAGES_ARCHIVED_OFFERS = "archived-offers",
	PAGES_PROMOTIONS_SEARCH_RESULTS = "promotions-search-results",
	PAGES_SEARCH_RESULTS = "search-results"
}

export interface AEMPagePath {
	id: AEMPageType
	path: string
}

export const AEMPagePaths: Record<AEMPageType, string> = {
	[AEMPageType.HOME]: "/home",
	[AEMPageType.PAGES_HOME]: "/pages/home",
	[AEMPageType.LOGIN]: "/login",
	[AEMPageType.FOOTER]: "/footer",
	[AEMPageType.PROMOTIONS_HOME]: "/pages/promotions-home",
	[AEMPageType.SEE_OUR_SHIPS]: "/ships",
	[AEMPageType.LANGUAGES]: "/languages",
	[AEMPageType.DICTIONARY]: "/dictionary",
	[AEMPageType.ALERTS]: "/alerts",
	[AEMPageType.NOTIFICATIONS]: "/notifications",
	[AEMPageType.NAVIGATION_FOOTER]: "/navigation/footer",
	[AEMPageType.NAVIGATION_GLOBAL]: "/navigation/global",
	[AEMPageType.NAVIGATION_SIDEBAR]: "/navigation/sidebar",
	[AEMPageType.NAVIGATION_HOME]: "/navigation/home",
	[AEMPageType.PAGES_PRIVACY_POLICY]: "/pages/privacy-policy",
	[AEMPageType.PAGES_TRAVEL_AGENT_GUIDELINES]: "/pages/travel-agent-guidelines",
	[AEMPageType.PAGES_TERMS_OF_USE]: "/pages/terms-of-use",
	[AEMPageType.PAGES_INSIGHTS_ALERT_CENTER]: "/pages/insights/alert-center",
	[AEMPageType.PAGES_INSIGHTS_COUPON_DETAILS]:
		"/pages/insights/coupon-application-details",
	[AEMPageType.PAGES_INSIGHTS_FUTURE_COMPENSATION]:
		"/pages/insights/future-compensation",
	[AEMPageType.PAGES_INSIGHTS_INDIVIDUAL_RESERVATIONS]:
		"/pages/insights/individual-reservations",
	[AEMPageType.PAGES_INSIGHTS_NPC_TRANSMISSION]:
		"/pages/insights/npc-transmission-details",
	[AEMPageType.PAGES_INSIGHTS_OVERVIEW]: "/pages/insights/overview",
	[AEMPageType.PAGES_INSIGHTS_AUTOMATED_EMAILS]:
		"/pages/insights/set-up-automated-emails",
	[AEMPageType.PAGES_INSIGHTS_VIEW_BRANCH]:
		"/pages/insights/view-another-branch",
	[AEMPageType.PAGES_PROMOTIONS_BRAND_LANDING_ROYAL]:
		"/pages/promotions-brand-landing-royal",
	[AEMPageType.PAGES_PROMOTIONS_OCTOBER_OFFER]:
		"/pages/promotions-offers/october-2024-monthlong-offer",
	[AEMPageType.PAGES_ARCHIVED_OFFERS]: "/pages/promotions-archived-offers",
	[AEMPageType.PAGES_PROMOTIONS_SEARCH_RESULTS]:
		"/pages/promotions-search-results",
	[AEMPageType.PAGES_SEARCH_RESULTS]: "/pages/search-results"
}

export enum AEMComponentMapper {
	WELCOME = "ComponentWelcomeModel",
	ESPRESSO_SEARCH_BAR = "ComponentEspressoBookingModel",
	ALERTS_SECTION = "ComponentBubbleTilesModel",
	CRUISE_ENHANCEMENTS = "ComponentBubbleTilesModel",
	MARKETING_CENTER = "ComponentBubbleTilesModel",
	PROMOTIONS_SEARCH_HOMEPAGE = "ComponentPromoSearchModel",
	FEATURE_OFFERS = "Component2UpCardsModel",
	TRAINING_AND_CERTIFICATION = "ComponentTrainingAndCertificationModel",
	LOYALTY_REWARDS = "ComponentLoyaltyRewardsModel",
	SHIP_LOCATOR = "ComponentShipLocatorModel",
	LEADERS_MODULE = "ComponentGroupLeadersModel",
	SIDE_IMAGE_CONTAINER = "ComponentSideImageContainerModel",
	LOGIN_HERO = "ComponentBackgroundHeroModel",
	LOGIN_FORM = "ComponentLoginFormModel",
	LOGIN_ASSISTANCE_MODAL = "ComponentLoginAssistanceModalModel",
	APP_FOOTER = "ComponentFooterModel",
	APP_NAVIGATION_FOOTER = "ComponentAppNavigationFooterModel",
	APP_LANGUAGES = "ComponentLanguagesModel",
	APP_DICTIONARY = "ComponentDictionaryModel",
	APP_GLOBAL_NAVIGATION = "ComponentGlobalNavigationModel",
	APP_NAVIGATION_SIDEBAR = "ComponentNavigationSidebarModel",
	PROMOTIONS_HOME = "ComponentRowOfCardsModel",
	APP_ALERTS = "ComponentAlertBannerList",
	APP_NOTIFICATIONS = "ComponentNotificationsList",
	PROMOTIONS_BRAND = "ComponentBrandSwitcherModel"
}
