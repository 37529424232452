import PeopleArrowIcon from "@assets/svg/drawers/people-arrow.svg?react"
import PersonIcon from "@assets/svg/drawers/person.svg?react"
import LogoutIcon from "@assets/svg/navigation/logout.svg?react"
import PeopleIcon from "@assets/svg/people.svg?react"
import {
	Avatar,
	Box,
	Divider,
	Group,
	Stack,
	Text,
	UnstyledButton
} from "@mantine/core"
import styles from "./AccountMenu.module.css"
import { ReactElement } from "react"
import { Role, MODIFY_AGENT_ACCESS_BTNLABEL } from "@utils/constants.util"
import { useRedirect } from "@hooks/useRedirect"
import { LinkType } from "navigation/LinksGroup"
import { getUserProfile } from "@services/authService"
import { useAuthContext } from "../../../context/AuthProvider"

const placeholderData = {
	topLinks: [
		{
			text: "Your Profile",
			icon: <PersonIcon />,
			url: "your-profile",
			type: "internal" as LinkType
		},
		{
			text: "Modify Agent Access",
			icon: <PeopleIcon />,
			url: "modify-agent-access",
			type: "internal" as LinkType
		}
	],
	bottomLinks: [
		{
			text: "Super User Log in",
			icon: <PeopleArrowIcon />,
			url: "super-user-login",
			type: "internal" as LinkType
		},
		{
			text: "Log out",
			icon: <LogoutIcon />,
			color: "blue",
			url: "/logout",
			type: "route" as LinkType
		}
	]
}

interface AccountMenuLinkInterface {
	icon: ReactElement
	text: string
	url: string
	type: LinkType
	color?: "blue" | "white"
	onClick?: () => void
}

function AccountMenuLink({
	icon,
	text,
	url,
	type,
	color = "white",
	onClick
}: AccountMenuLinkInterface) {
	const { handleRedirect } = useRedirect()
	const textColorClass =
		color === "blue" ? styles.accountMenuLinkAlt : styles.accountMenuLink
	const buttonColorClass =
		color === "blue" ? styles.accountMenuButtonBlue : styles.accountMenuButton

	const handleClick = () => {
		if (onClick) {
			onClick()
			return
		}
		handleRedirect({ link: url, type: type })
	}

	return (
		<UnstyledButton
			onClick={handleClick}
			p="xl"
			className={buttonColorClass}
			w="100%"
		>
			<Group gap="lg" align="center">
				<Group className={textColorClass}>{icon}</Group>
				<Text fz={16} fw={600} className={textColorClass}>
					{text}
				</Text>
			</Group>
		</UnstyledButton>
	)
}

export function AccountMenu() {
	const { logout } = useAuthContext()

	const userProfile = getUserProfile()
	const {
		agentFirstName,
		agentLastName,
		emailAddress,
		agencyName,
		agentPhotos,
		roleCode
	} = userProfile
	const fullPathProfilePath = agentPhotos?.AGENT_HOMEPAGE_PHOTO?.fullPath

	return (
		<Stack gap={0} h="100%" mih="100%" justify="space-between">
			<Stack gap={0}>
				<Group align="flex-start" wrap="nowrap" px="md" py="xl">
					<Avatar src={fullPathProfilePath} size={40} />
					<Box>
						<Text fz={14} lh={1.4} fw={600}>
							{`${agentFirstName} ${agentLastName}`}
						</Text>
						<Text fz={14} fw={400} lh={1.4}>
							{emailAddress}
						</Text>
						<Text fz={12} fw={400} lh={1.7}>
							{agencyName}
						</Text>
					</Box>
				</Group>
				<Divider />
				{placeholderData.topLinks.map((link, index) => (
					<Box key={`top-link-${index}`}>
						{MODIFY_AGENT_ACCESS_BTNLABEL !== link.text ? (
							<>
								<AccountMenuLink key={`top-link-${index}`} {...link} />
								<Divider key={`top-link-divider-${index}`} />
							</>
						) : (
							(roleCode === Role.ADMIN || roleCode === Role.RCCLADMIN) && (
								<>
									<AccountMenuLink key={`top-link-${index}`} {...link} />
									<Divider key={`top-link-divider-${index}`} />
								</>
							)
						)}
					</Box>
				))}
			</Stack>
			<Stack gap={0}>
				<Divider />
				{(roleCode === Role.RCCL || roleCode === Role.RCCLADMIN) && (
					<AccountMenuLink
						text="Super User Log in"
						icon={<PeopleArrowIcon />}
						url="super-user-login"
						type="internal"
					/>
				)}
				<Divider />
				<AccountMenuLink
					text="Log out"
					url="/logout"
					icon={<LogoutIcon />}
					onClick={logout}
					color="blue"
					type="route"
				/>
			</Stack>
		</Stack>
	)
}
