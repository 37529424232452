import { changeLocationData, getUserProfile } from "@services/authService"

export const getLocation = (lat?: string, long?: string) => {
	const userProfile = getUserProfile()
	if (lat && long) {
		changeLocationData(lat, long)
	}

	return { lat: lat ?? userProfile?.lat, long: long ?? userProfile?.long }
}
