import { create } from "zustand"
import { persist } from "zustand/middleware"
import { NotificationItem } from "@components/drawers/Notifications/Notifications"
import {
	parseISO,
	isWithinInterval,
	startOfDay,
	endOfDay,
	isBefore,
	isAfter
} from "date-fns"
import secureLocalStorage from "react-secure-storage"

interface NotificationStore {
	notifications: NotificationItem[]
	dismissedIds: string[]
	setNotifications: (notifications: NotificationItem[]) => void
	dismissNotification: (id: string) => void
	isWithinDateRange: (start: string | null, end: string | null) => boolean
}

export const useNotificationStore = create<NotificationStore>()(
	persist(
		(set, get) => ({
			notifications: [],
			dismissedIds: [],
			setNotifications: (notifications) => {
				const validNotifications = notifications.filter((notification) => {
					return get().isWithinDateRange(notification.start, notification.end)
				})
				set({ notifications: validNotifications })
			},
			dismissNotification: (id) =>
				set((state) => ({
					notifications: state.notifications.filter((item) => item.id !== id),
					dismissedIds: [...state.dismissedIds, id]
				})),
			isWithinDateRange: (start: string | null, end: string | null) => {
				const today = startOfDay(new Date())

				if (!start && !end) return true

				try {
					if (start && end) {
						const startDate = startOfDay(parseISO(start))
						const endDate = endOfDay(parseISO(end))

						return isWithinInterval(today, { start: startDate, end: endDate })
					}

					if (start && !end) {
						const startDate = startOfDay(parseISO(start))
						return (
							isAfter(today, startDate) ||
							today.getTime() === startDate.getTime()
						)
					}

					if (!start && end) {
						const endDate = endOfDay(parseISO(end))
						return (
							isBefore(today, endDate) || today.getTime() === endDate.getTime()
						)
					}

					return false
				} catch (error) {
					return false
				}
			}
		}),
		{
			name: "notification-storage",
			storage: {
				getItem: (name) => {
					const str = secureLocalStorage.getItem(name)
					return str ? JSON.parse(str) : null
				},
				setItem: (name, value) => {
					secureLocalStorage.setItem(name, JSON.stringify(value))
				},
				removeItem: (name) => secureLocalStorage.removeItem(name)
			}
		}
	)
)
