import { PageWithHeader } from "@components/templates/PageWithHeader/PageWithHeader"
import { useAuthContext } from "../../../context/AuthProvider"

interface ConditionalPageLayoutProps {
	children: React.ReactNode
}

// TODO: Santiago Ponce: This component should be deleted, we need to merge both page with header and page with navigation into one single configurable component
export function ConditionalPageLayout({
	children
}: ConditionalPageLayoutProps) {
	const { isAuthenticated } = useAuthContext()

	if (isAuthenticated) {
		return children
	}

	return <PageWithHeader>{children}</PageWithHeader>
}
