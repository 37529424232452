import { Stack } from "@mantine/core"
import { WeatherWidget } from "@components/ui/WeatherWidget/WeatherWidget"
import { CurrencyConverter } from "@components/ui/CurrencyConverter/CurrencyConverter"

export function YourExtensions() {
	return (
		<Stack gap="lg" justify="space-between" pt={0} pb="xl" px="xl">
			<WeatherWidget />
			<CurrencyConverter />
		</Stack>
	)
}
